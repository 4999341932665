<template>
  <el-card>
    <div slot="header">
      <h2>{{this.productForm.id ? $t('button.edit') : $t('button.add')}}</h2>
    </div>

    <el-form
        ref="addActivityRef"
        :rules="createProductRules"
        :model="productForm"
        label-width="80px">

      <el-form-item :label="$t('lang')">
        <el-radio v-model="lang" label="zh-CN">中文</el-radio>
        <el-radio v-model="lang" label="en-US">English</el-radio>
      </el-form-item>

      <el-form-item :label="$t('page.product.name')" prop="title">

        <el-col :span="8" v-if="lang == 'zh-CN'">
          <el-input v-model="productForm.title"></el-input>
        </el-col>

        <el-col :span="8" v-else>
          <el-input v-model="productForm.en_title"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item :label="$t('page.product.model')" prop="model">
        <el-col :span="8" v-if="lang == 'zh-CN'">
          <el-input v-model="productForm.model"></el-input>
        </el-col>
        <el-col :span="8" v-else>
          <el-input v-model="productForm.en_model"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item :label="$t('page.product.desc')">
        <el-col :span="12" v-if="lang == 'zh-CN'">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="productForm.desc"></el-input>
        </el-col>
        <el-col :span="12" v-else>
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="productForm.en_desc"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item :label="$t('page.product.images')">
        <el-row class="images-preview" :gutter="20" type="flex" justify="start">
          <el-col :span="4" v-for="(url, index) in productForm.images" :key="'images-preview-'+index">
            <div class="images-preview-center">
              <el-image
                  fit="cover"
                  lazy
                  :src="url"
                  :preview-src-list="productForm.images">
              </el-image>
              <div class="images-preview-mask">
                <el-button type="danger" icon="el-icon-delete" circle @click="remove(index)"></el-button>
              </div>
            </div>
          </el-col>

          <el-col :span="4">
            <div class="images-upload" @click="selectImages('images')">
              <i class="el-icon-plus"></i>
            </div>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item :label="$t('page.product.product_type')">
        <el-select multiple filterable v-model="select.productType">
          <el-option-group
              v-for="group in options.productType"
              :key="group.id"
              :label="group.name">
            <el-option
                :key="group.id"
                :label="group.name"
                :value="group.id">
            </el-option>
            <el-option
                v-for="item in group.children"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>


      <el-form-item :label="$t('page.product.product_app')">
        <el-select multiple filterable v-model="select.productApp">
          <el-option-group
              v-for="group in options.productApp"
              :key="group.id"
              :label="group.name">
            <el-option
                :key="group.id"
                :label="group.name"
                :value="group.id">
            </el-option>
            <el-option
                v-for="item in group.children"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('page.product.workpiece')">
        <el-select multiple filterable v-model="select.workpiece">
          <el-option-group
              v-for="group in options.workpiece"
              :key="group.id"
              :label="group.name">
            <el-option
                :key="group.id"
                :label="group.name"
                :value="group.id">
            </el-option>
            <el-option
                v-for="item in group.children"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('page.product.subsidiary')">
        <el-select multiple filterable v-model="select.subsidiary">
          <el-option-group
              v-for="group in options.subsidiary"
              :key="group.id"
              :label="group.name">
            <el-option
                :key="group.id"
                :label="group.name"
                :value="group.id">
            </el-option>
            <el-option
                v-for="item in group.children"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>


      <!--<el-form-item :label="$t('page.product.product_type')">
        <el-cascader
            ref="productType"
            v-model="select.productType"
            :options="options.productType"
            :props="props"
            clearable></el-cascader>
      </el-form-item>

      <el-form-item :label="$t('page.product.product_app')">
        <el-cascader
            ref="productApp"
            v-model="select.productApp"
            :options="options.productApp"
            :props="props"
            clearable></el-cascader>
      </el-form-item>

      <el-form-item :label="$t('page.product.workpiece')">
        <el-cascader
            ref="workpiece"
            v-model="select.workpiece"
            :options="options.workpiece"
            :props="props"
            clearable></el-cascader>
      </el-form-item>

      <el-form-item :label="$t('page.product.subsidiary')">
        <el-cascader
            ref="subsidiary"
            v-model="select.subsidiary"
            :options="options.subsidiary"
            :props="props"
            clearable></el-cascader>
      </el-form-item>-->


      <!--<el-form-item :label="$t('page.product.product_type')">
        <el-select multiple v-model="select.productType" filterable>
          <el-option
              v-for="item in options.productType"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('page.product.product_app')">
        <el-select multiple v-model="select.productApp" filterable>
          <el-option
              v-for="item in options.productApp"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('page.product.workpiece')">
        <el-select multiple v-model="select.workpiece" filterable>
          <el-option
              v-for="item in options.workpiece"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('page.product.subsidiary')">
        <el-select multiple v-model="select.subsidiary" filterable>
          <el-option
              v-for="item in options.subsidiary"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>-->

      <!--<el-form-item label="参数设置">
        <el-table :data="productForm.attrs" border>
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column prop="date" label="属性名">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="date" label="属性值">
            <template slot-scope="scope">
              <el-input v-model="scope.row.value" size="small" clearable></el-input>
            </template>
          </el-table-column>
        </el-table>

        <el-button type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('attrs')">添加</el-button>
      </el-form-item>-->

      <el-form-item :label="$t('page.product.content')">
        <editor-bar v-show="lang == 'zh-CN'" ref="editor" :content.sync="productForm.content" :isClear="isClear" @selectImages="selectImages"  @change="change"></editor-bar>
        <editor-bar v-show="lang == 'en-US'" ref="en_editor" :content.sync="productForm.en_content" :isClear="isClear" @selectImages="selectImages"  @change="changeEn"></editor-bar>
      </el-form-item>


      <el-form-item :label="$t('page.product.wxVideo')">
        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="productForm.wx_video_id"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" size="mini" @click="onSave('addActivityRef')">{{$t('button.save')}}</el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogVisible" :title="$t('tips.select')">
      <select-image
          :multiple="true"
          :active="false"
          @select="getImages"
          @close="dialogVisible = false">
      </select-image>
    </el-dialog>
  </el-card>
</template>

<script>
import selectImage from '@/components/Images';
import EditorBar from '@/components/Editor'
import {getListTaxonomy, getTaxonomyAll, updateProduct, createProduct, getProduct} from '@/api/os/admin';

export default {
  data() {
    return {
      lang: 'zh-CN',
      dialogVisible: false,
      productForm: {
        title: '',
        model: '',
        desc: '',
        // attrs: [],
        content: '',
        images: [],

        en_title: '',
        en_model: '',
        en_desc: '',
        en_content: '',
        wx_video_id: ''
      },
      isClear: false,

      options: {
        productType: [],
        productApp: [],
        subsidiary: [],
        workpiece: []
      },
      select: {
        productType: [],
        productApp: [],
        subsidiary: [],
        workpiece: []
      },

      selectType: 'images',
      props: {
        multiple: true,
        label: 'name',
        value: 'id',
        checkStrictly: true
      }
    }
  },
  components: {
    selectImage,
    EditorBar
  },
  async created() {
    // this.getTaxonomy()
    this.options.productType = await this.getTaxonomy({type: 'productType'});
    this.options.productApp = await this.getTaxonomy({type: 'productApp'});
    this.options.subsidiary = await this.getTaxonomy({type: 'subsidiary'});
    this.options.workpiece = await this.getTaxonomy({type: 'workpiece'});

    if(this.$route.query.id) {
      getProduct({
        id: this.$route.query.id,
      }).then(res => {
        this.productForm = res.data.data
        this.select = res.data.data.taxonomy
        this.$refs.editor.setHtml(this.productForm.content)
        // en
        this.$refs.en_editor.setHtml(this.productForm.en_content)
      })
    }
  },
  computed: {
    // add
    createProductRules() {
      return {
        title: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // getTaxonomy() {
    //   getListTaxonomy().then(res => {
    //     this.options = res.data.data
    //   })
    // },
    getTaxonomy(data) {
      return new Promise(resolve => {
        getTaxonomyAll(data).then(res => {
          let data = res.data.data;
          resolve(data)
        })
      })
    },

    getImages(data) {
      this.dialogVisible = false;
      let list = '';
      if(data && data.length > 0) {
        data.forEach(e => {
          list += `<img src="${e}">`;
        })
      }

      if(this.selectType == 'images') {
        return this.productForm.images = this.productForm.images.concat(data)
      }
      if(this.lang == 'en-US'){

        return this.$refs.en_editor.insertImages(list);
      }
      if(this.lang == 'zh-CN'){

        return this.$refs.editor.insertImages(list)
      }
    },
    deleteImage(index) {
      this.productForm.images.splice(index, 1)
    },
    addAttrs(name) {
      this.productForm[name].push({
        name: '',
        value: ''
      })
    },
    remove(index) {
      this.productForm.images.splice(index, 1)
    },
    change(val) {
      this.productForm.content = val
    },
    changeEn(val) {
      this.productForm.en_content = val
    },
    selectImages(type) {
      this.dialogVisible = true
      this.selectType = type
    },

    goTo() {
      this.$router.push('/console/productList')
    },

    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {

          return this.$message.warning('请排查表单必填项');
        }

        let taxonomy = [];
        if (this.select.productType) {
          // let productType = this.$refs['productType'].getCheckedNodes()
          // productType.forEach((e, i) => {
          //   taxonomy.push(e.value)
          // })
          taxonomy = taxonomy.concat(this.select.productType);
        }
        if (this.select.productApp) {
          // let productApp = this.$refs['productApp'].getCheckedNodes()
          // productApp.forEach((e, i) => {
          //   taxonomy.push(e.value)
          // })
          taxonomy = taxonomy.concat(this.select.productApp)
        }
        if (this.select.workpiece) {
          // let workpiece = this.$refs['workpiece'].getCheckedNodes()
          // workpiece.forEach((e, i) => {
          //   taxonomy.push(e.value)
          // })
          taxonomy = taxonomy.concat(this.select.workpiece)
        }
        if (this.select.subsidiary) {
          // let subsidiary = this.$refs['subsidiary'].getCheckedNodes()
          // subsidiary.forEach((e, i) => {
          //   taxonomy.push(e.value)
          // })
          taxonomy = taxonomy.concat(this.select.subsidiary)
        }


        let data = this.productForm;
        data.taxonomy = taxonomy;
        if (this.productForm.id) {
          updateProduct(data).then(res => {
            this.$message.success(this.$t('tips.success'));
            this.goTo()
          })
        } else {
          createProduct(data).then(res => {
            this.$message.success(this.$t('tips.success'));
            this.goTo()
          })
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.images-upload{
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 128px;
  cursor: pointer;
  line-height: 126px;
  vertical-align: top;
  text-align: center;
  i{
    font-size: 28px;
    color: #8c939d;
  }
}
.images-preview{
  flex-wrap: wrap;
  flex-direction: row;
  .el-col{
    margin-bottom: 20px;
  }
  .images-preview-center{
    position: relative;
    height: 100%;
    &:hover{
      .images-preview-mask {
        display: block;
      }
    }
    .el-image{
      width: 100%;
      //height: 100px;
      display: block;
      cursor: pointer;
    }
    .images-preview-mask{
      width: 100%;
      height: 50px;
      line-height: 50px;
      position: absolute;
      bottom: 0;
      background-color: rgba(0,0,0,.6);
      display: none;
      text-align: center;
      .el-button{
      }
    }
  }
}
</style>